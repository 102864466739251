.form-join-select__option {
  position: relative; }

.form-join-select__shortcut {
  position: absolute;
  top: 0.5em;
  right: 0.5em;

  &, .field.has-addons .control &.button:not([disabled]):hover {
    z-index: 10; }

  .form-join-select__shortcut-icon {
    &, .control.has-icons-left &, .control.has-icons-right & {
      position: relative;
      height: 1.5rem;
      width: 1.5rem;
      color: inherit;
      margin-left: 0;
      margin-right: -0.75em; } } }


.form-join-select--has-shortcut {
  &, &.input {
    padding-right: 5em; } }
