.columns {
  &.is-multiline {
    &.reporting {
      display: block !important;

      @media print {
        font-size: 11pt !important; }

      .column {
        padding: .25rem .75rem;

        &.is-two-fifths,
        &.is-three-fifths {
          display: inline-block;
          vertical-align: top; }

        @media screen and ( max-width: 768px ) {
          &.is-two-fifths {
            display: block; }

          &.is-three-fifths {
            display: block;
            margin-bottom: 10px; } }


        @media print {
          font-size: 9pt !important;
          padding: 0 !important;

          &.reporting__hide-print {
            display: none; }

          &.is-two-fifths.has-text-weight-bold {
            font-weight: normal !important; }

          &.my-5 {
            margin-bottom: 10px !important;
            margin-top: 10px !important; } }

        &.reporting__data {
          column-width: 25rem;
          column-gap: 4rem;
          padding: 0 20px;

          @media screen and ( max-width: 880px ) {
            column-width: 20rem;
            column-gap: 2rem; }

          @media screen and ( max-width: 700px ) {
            column-width: 15rem;
            column-gap: 1rem; }

          @media print {
            column-width: 14rem;
            column-gap: 20px;
            column-span: all;
            font-size: 7pt !important; } } }

      .reporting__subkey {
        padding-left: 6rem;

        @media print {
          padding-left: 40px; } }

      .reporting__print-header {
        display: none;
        text-align: center;
        font-size: 11pt;
        font-weight: bold;

        @media print {
          display: block; }

        .reporting__print-header__logo {
          margin-bottom: 15px;
          max-width: 200px; }

        .reporting__print-header__title {
          margin-bottom: 15px; } } } } }

.report-print-row {
  break-inside: avoid;
  page-break-inside: avoid;

  @media print {
    &.mb-3 {
      margin-bottom: 0 !important; } } }

@page {
  margin: 1em; }
