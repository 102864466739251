.card {
  cursor: pointer; }

.card-content {
  @media screen and (max-width: 480px) {
    .button {
      height: 3rem;
      margin-right: 1rem;
      padding-bottom: .5rem;
      padding-left: .5rem;
      padding-right: .5rem;
      padding-top: .5rem;

      &:last-child {
        margin-right: 0; }

      &.is-fullwidth {
        display: inline-block;
        width: auto; }

      span {
        &.icon {
          &:first-child {
            font-size: 1.25rem;
            margin-left: 0;
            margin-right: 0; } }

        &:last-child {
          display: none; } } } } }
