.hero {
  &.is-fullheight-with-navbar {

    .hero-body {

      &.align-to-start {
        align-items: flex-start; } } } }

.button {
  &.is-dark {
    &.mod-disabled {
      &:disabled,
      &[disabled] {
        background-color: $grey-lighter;
        color: $grey; } } } }

.has-no-gap {
  &.divider {
    &:not(.is-right) {
      &::after {
        margin-left: 0; } }

    &:not(.is-left) {
      &::before {
        margin-right: 0; } } } }

.hero {
  .tabs {
    ul {
      border-bottom: 1px solid $grey-lighter; } } }

.tabs {
  &.is-boxed {
    a {
      background-color: $white-ter;
      border-bottom-color: $grey-lighter; } } }

.file.is-focused > .file-label {
    border-color: $button-focus-border-color;
    color: $button-focus-color;
    &:not(:active) {
      box-shadow: $button-focus-box-shadow-size $button-focus-box-shadow-color; } }
