$navbar-height: 5rem;
$navbar-item-img-max-height: 3rem;
$navbar-background-color: $rich-black;
$footer-padding: 1rem;
$tabs-toggle-link-active-background-color: $dark-color;
$tabs-toggle-link-active-border-color: $dark-color;
$tabs-link-active-color: $dark-color;
$tabs-boxed-link-active-background-color: $white;
$tabs-boxed-link-hover-background-color: $grey-light;

$cdn-url: 'https://cdn.taamusic.com';

@function get-asset($local-path) {
  @return url($cdn-url + $local-path); }
