.tabs {
  &.is-boxed {
    ul {
      @media screen and (max-width: 550px) {
        border-bottom: 0;
        flex-direction: column; }
      li {
        @media screen and (max-width: 550px) {
          width: 100%;

          &.is-active {
            a {
              border: 1px solid $grey-lighter;
              border-bottom-color: $grey-lighter !important;

              + a {
                margin-top: 1px; } } } }
        a {
          @media screen and (max-width: 820px) {
            flex-direction: column; }

          @media screen and (max-width: 550px) {
            border: 1px solid $white-ter;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            margin-bottom: 0; }

          .icon {
            @media screen and (max-width: 820px) {
              margin-bottom: .25rem;
              margin-right: 0; } } } } } } }
