.DayPicker {
  &.is-danger {
    border: 1px solid  $danger;
    border-radius: 4px; } }

.is-weekSelector {
  .DayPicker-Month {
    border-collapse: separate; }

  .DayPicker-WeekNumber {
    outline: none; }

  .DayPicker-Day {
    outline: none;
    border: 1px solid transparent; }

  .DayPicker-Day--hoverRange {
    background-color: #EFEFEF !important; }


  .DayPicker-Day--selectedRange {
    background-color: #fff7ba !important;
    border-top-color: #FFEB3B;
    border-bottom-color: #FFEB3B;
    border-left-color: #fff7ba;
    border-right-color: #fff7ba; }


  .DayPicker-Day--selectedRangeStart {
    background-color: #FFEB3B !important;
    border-left: 1px solid #FFEB3B; }


  .DayPicker-Day--selectedRangeEnd {
    background-color: #FFEB3B !important;
    border-right: 1px solid #FFEB3B; }


  .DayPicker-Day--selectedRange.DayPicker-Day--selected,
  .DayPicker-Day--hoverRange.DayPicker-Day--selected {
    border-radius: 0 !important;
    color: black !important; }

  .DayPicker-Day--hoverRange:hover {
    border-radius: 0 !important; } }

.DayPickerInput {
  .DayPickerInput-Overlay {
    z-index: 5; } }
