.form-join-checklist__status-column {
  display: none;

  @media ( min-width: $tablet ) {
    display: table-cell; } }

.form-join-checklist__status-mobile {
  display: inline;

  @media ( min-width: $tablet ) {
    display: none; } }

.form-join-checklist__table {
  font-size: 0.75em;

  @media ( min-width: $tablet ) {
    min-width: 35rem;
    font-size: 1em; } }

.form-join-checklist__control-cell {
  min-width: 13em;
  // font-size: 0.75em

  & .button {
    font-size: 1em; }

  // @media ( min-width: $tablet )
 }  //   font-size: 1em

// .form-join-checklist
//   &:focus
//     border: 2px solid #f0f

.form-join-checklist__tr {
  position: relative;

  strong {
    color: inherit; } }

.form-join-checklist__highlight-scroller {
  position: absolute;
  top: -6em;
  height: 16em;
  width: 1em;
  pointer-events: none;
 }  // border: 1px solid #404
