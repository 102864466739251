// Class generator mixin
@mixin helper-class-generator($selector, $rule, $value) {
  #{$selector} {
    #{$rule}: $value; } }

// Helper Classes
@include helper-class-generator('.it-is-fullwidth', width, 100%);
@include helper-class-generator('.has-no-top-margin', margin-top, 0 !important);
@include helper-class-generator('.has-medium-vertical-padding', padding, .75rem 0);
@include helper-class-generator('.has-medium-top-margin', margin-top, .75rem);
@include helper-class-generator('.get-my-position-back', margin-top, -$navbar-height);
@include helper-class-generator('.has-columndirection', flex-direction, column);
@include helper-class-generator('.has-text-strike', text-decoration, line-through);
@include helper-class-generator('.has-cells-vertically-centered th', vertical-align, middle);
@include helper-class-generator('.has-cells-vertically-centered td', vertical-align, middle);
@include helper-class-generator('.is-width-100', width, 100%);

.is-scrollable-clamped {
  max-height: 12em;
  overflow-y: scroll; }
