.loading__wrapper {
  height: 100vh;
  margin-top: -$navbar-height;
  position: relative;
  width: 100vw;

  .loading__inner-wrapper {
    height: 192px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 192px;

    .loading__inner-breather {
      animation: breathe 2s ease-out infinite both;
      background-image: get-asset('/Website-Spinner.png');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 125px;
      left: 33.5px;
      position: absolute;
      top: 33.5px;
      width: 125px; }

    .loading__outer-spinner {
      animation: spin 1s infinite linear;
      border: 2px solid $white;
      border-radius: 100%;
      border-top: 3px solid $dark-blue;
      height: 192px;
      margin: auto;
      opacity: 1;
      width: 192px;
      z-index: 10; }

    @keyframes spin {
      from {
        transform: rotate(0deg); }

      to {
        transform: rotate(360deg); } }

    @keyframes breathe {
      0% {
        transform: scale(1); }

      50% {
        transform: scale(1.1); }

      100% {
        transform: scale(1); } } } }

