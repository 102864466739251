.report-table {
  margin-top: 2rem;

  @media screen and ( max-width: 950px ) {
    thead {
      display: none; }

    tbody {
      tr {
        border: 1px solid $border;
        display: block;
        margin-top: 3rem;

        td {
          border-bottom: 1px solid $border !important;
          display: block;
          padding: 5px 0 5px 33%;
          position: relative;

          &::before {
            content: attr(data-heading);
            display: block;
            font-weight: bold;
            left: 3%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%); } } } }


    @media screen and ( max-width: 580px ) {
      tbody {
        tr {

          td {
            padding: 5px 0 5px 43%; } } } }

    @media screen and ( max-width: 450px ) {
      tbody {
        tr {

          td {
            padding: 5px 0 5px 63%; } } } } } }
