.card {
  &.comms-add {
    height: 100%;

    .card-content {
      height: 100%;

      .comms-add__btn {
        border: 0;
        font-size: 5rem;
        height: 100%;
        user-select: none;

        &:active {
          border: 0;
          box-shadow: none; }

        @media screen and (max-width: 480px) {
          align-items: center;
          display: flex;
          justify-content: center;
          width: 100%;

          span {
            display: inline;
            font-size: 3rem; } } } } } }
