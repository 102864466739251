.navbar-brand {
  &.navbar-search {
    flex-shrink: 1;

    .navbar-item {
      &:first-child {
        left: 0;
        transition: left .2s;

        @media screen and (max-width: 480px) {
          width: 40%; } } }

    .navbar-burger {
      right: 0;
      transition: right .2s; }

    .navbar-search__search-field {
      flex-shrink: 1;

      .field {
        margin-bottom: 0; }

      .navbar-search__search-reset {
        background: transparent;
        border: 0;
        left: 65%;
        opacity: 0;
        outline: 0;
        position: absolute;
        top: 8px;
        transition: opacity .2s;
        transition-delay: 0;
        visibility: hidden;
        z-index: 4;

        &.show-reset {
          opacity: 1;
          transition-delay: .2s;
          visibility: visible; }

        &:focus {
          outline-color: $white; } }

      @media screen and (max-width: 480px) {
        left: 50%;
        position: absolute;
        top: 4px;
        transform: translateX(-50%);

        .field {
          &.has-addons {
            .control {
              button {
                border-bottom-left-radius: 290486px;
                border-top-left-radius: 290486px; } } }

          .control {
            &:first-child {
              overflow: hidden;
              transition: width .2s;
              width: 0; } } } }

      input {
        box-shadow: none;

        &:active {
          box-shadow: none; } } }

    &.openSearch {
      @media screen and (max-width: 480px) {
        .navbar-search__search-field {

          .field {
            &.has-addons {
              .control {
                button {
                  border-bottom-left-radius: 0;
                  border-top-left-radius: 0; } } }

            .control {
              &:first-child {
                overflow: hidden;
                width: 225px; } } } }

        .navbar-item {
          &:first-child {
            left: -100%; } }

        .navbar-burger {
          right: -100%; } } } } }


.navbar-item {
  &.has-dropdown {
    .navbar-dropdown {
      display: none; }

    &.is-active {
      .navbar-dropdown {
        display: block; } } } }
