.music-file-manager {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 28em); }

.music-file-manager__spacer {
  flex-grow: 1; }

.music-file-manager__dnd-area {
  margin: 2em;
  padding: 2em;
  border: 2px dashed $grey-light; }

.music-file-manager__dnd-area--dropping {
  border-color: $primary; }

.music-file-manager__dnd-label {
  text-align: center; }

.music-file-manager__dnd-title {
  font-size: 1.5em;
  line-height: 1; }

.music-file-manager__dnd-or {
  line-height: 1;
  padding: 0.5em 0;
  color: $text-light; }

.music-file-manager__dnd-statuses {
  font-size: 0.75em;
  margin-top: 2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; }

.music-file-manager__dnd-status {
  padding: 0 1em; }
